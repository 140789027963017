import { casePhotos } from "src/constant/constants"
import { formatNodes } from "src/helpers"
import { DIGITIZE_IMAGE, SET_ERROR, LOADING, CHANGE_IMAGE_ATTRIBUTE, CLEAR_DIGITIZATION, CALIBRATE_IMAGE_SIZE, SAVE_DIGITIZATION, LATERAL_CEPH_IMAGE_UPLOADED, LOAD_SERVICE, UPDATE_VIEW_SETTINGS, APPLAY_ANALYSIS, SET_TAB, UPDATE_RULER_SIZE, UPDATE_POINTS, SET_LETRAL_CEPH_META, SELECT_ANALYSIS_LINE, LOAD_DIGITIZATION_RESULT, RESTORE_DEFAULT_DIGITIZATION, EDIT_DIGITIZATION, CROPING_MODE, CROPING_DATA, LATERAL_CEPH_IMAGE_CLASIFIED} from "../actions/cephalometry"

export const CephalometryInitalState = {
    serviceId: "",
    data: {},
    /// Images list of patiant case, [Lateral Cephalometry] is used for digitization ...
    casePhotos: [...casePhotos],
    /// Leteral Cephalometry image properties ...
    imgHeight: null,
    imgWidth: null,
    imgBrightness: 50,
    imgContrast: 50,
    /// Cephalometry Graph
    originalPoints: [],
    points: [],

    graphHeight: 800,
    graphWidth: 700,

    appliedAnalysis: {},
    selectedAnalysisLine: {},

    cropingData: {},

    /// Flags
    mode: 'DEFAULT',
    lateralCephImageUploaded: false,
    lateralCephImage: 'None',
    digitization: false,
    blockDigitization: false,
    allowEditPoint: false,
    imageCalibrated: false,
    rulerSize: { mm: 10.0, point: 10.0 },
    viewSettings: {
        viewMode: "Line Analysis",
        cephalometry: true,
        tracingLine: false,
        analysisLine: true,
        measurement: true,
        points: true,
        teeth: true,
        curvePoints: false,
    },
    /// Loading and error state
    tab: "",
    loading: false,
    error: ""
}

const cephalometryReducer = (state = CephalometryInitalState, action) => {
    switch (action.type) {
        case LOAD_SERVICE:
            let newCasePhotos = [...state.casePhotos]
            action.payload.serviceData.files.map((file) => {
                let index = newCasePhotos.findIndex((item) => item.lable === file.name)
                newCasePhotos[index] = {...newCasePhotos[index], url: file.file, ...file }
            })
            return { ...state, loading: false, error: "", serviceId: action.payload.serviceData.id, data: action.payload.serviceData, casePhotos: [...newCasePhotos],}
        case LOAD_DIGITIZATION_RESULT:
            return {...state, blockDigitization: true, digitization: true, allowEditPoint: false, imageCalibrated: false, points: action.payload.points, originalPoints: action.payload.originalPoints}
        case LATERAL_CEPH_IMAGE_UPLOADED:
            return { ...state, lateralCephImageUploaded: true }
        case LATERAL_CEPH_IMAGE_CLASIFIED:
            return { ...state, lateralCephImage: action.payload }
        case SET_LETRAL_CEPH_META:
            return { ...state, imgWidth: action.payload.width, imgHeight: action.payload.height }
        case CROPING_MODE:
            return { ...state, mode: action.payload ? 'CROPING' : 'DEFAULT' }
        case CROPING_DATA:
            return { ...state, cropingData: action.payload }
        case DIGITIZE_IMAGE:
            return { ...state, error: "", loading: false, blockDigitization: true, digitization: true, allowEditPoint: true, imageCalibrated: false, points: action.payload.formatedPoint, originalPoints: action.payload.originalPoints }
        case CLEAR_DIGITIZATION:
            return { ...state, digitization: false, allowEditPoint: false, imageCalibrated: false, points: [], appliedAnalysis: {} }
        case RESTORE_DEFAULT_DIGITIZATION:
            return { ...state, error: "", loading: false, digitization: true, allowEditPoint: true, imageCalibrated: false, points: [...formatNodes(state.originalPoints)]}
        case CALIBRATE_IMAGE_SIZE:
            return { ...state, imageCalibrated: true }
        case UPDATE_RULER_SIZE:
            return { ...state, rulerSize: { ...state.rulerSize, ...action.payload } }
        case SAVE_DIGITIZATION:
            return { ...state, allowEditPoint: false, appliedAnalysis: action.payload, imageCalibrated: false }
        case EDIT_DIGITIZATION:
            return { ...state, allowEditPoint: true, appliedAnalysis: {}, imageCalibrated: false }
        case UPDATE_POINTS: {
            let newPoints = [...state.points]
            let index = newPoints.findIndex((point) => point.name === action.payload.name)
            newPoints[index].x = action.payload.x
            newPoints[index].y = action.payload.y
            return { ...state, points: [...newPoints] }
        }

        case UPDATE_VIEW_SETTINGS:
            return { ...state, viewSettings: { ...state.viewSettings, ...action.payload } }
        case CHANGE_IMAGE_ATTRIBUTE:
            if (action.payload.attribute === "brightness")
                return { ...state, imgBrightness: action.payload.newValue }
            else if (action.payload.attribute === "contrast")
                return { ...state, imgContrast: action.payload.newValue }
            else
                return state

        case APPLAY_ANALYSIS:
            return { ...state, appliedAnalysis: action.payload }
        case SELECT_ANALYSIS_LINE:
            return { ...state, selectedAnalysisLine: { ...action.payload } }
        case SET_TAB:
            return { ...state, tab: action.payload }
        case SET_ERROR:
            return { ...state, error: action.payload, loading: false, digitization: false, points: [] }
        case LOADING:
            return { ...state, loading: true }
        default:
            return state
    }
}

export default cephalometryReducer