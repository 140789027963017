import { SET_AUTHENTICATION } from "src/redux/actions/user"

const InitalUserState = {
    idToken: "",
    token: "",
    email_address: "",
}

const userReducer = (state = InitalUserState, action) => {
    switch (action.type) {
        case SET_AUTHENTICATION:
            //console.log(action.payload.token)
            return {
                ...state,
                idToken: action.payload.idToken,
                token: action.payload.token,
                email_address: action.payload.email_address,
            }
        default:
            return state
    }
}

export default userReducer