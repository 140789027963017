import store from "../store"

import {dataURLtoFile, formatNodes, getMeta } from "../../helpers"

import { addServiceFile, analysisLetralImage, clasifyLetralImage, getService, updateService, updateServiceFile } from "../../utils/ceph"

export const LOAD_SERVICE = "LOAD_SERVICE"
export const LOAD_DIGITIZATION_RESULT = "LOAD_DIGITIZATION_RESULT"
export const LATERAL_CEPH_IMAGE_UPLOADED = "LATERAL_CEPH_IMAGE_UPLOADED"
export const LATERAL_CEPH_IMAGE_CLASIFIED = "LATERAL_CEPH_IMAGE_CLASIFIED"
export const SET_LETRAL_CEPH_META = "SET_LETRAL_CEPH_META"
export const DIGITIZE_IMAGE = "DIGITIZE_IMAGE"
export const CLEAR_DIGITIZATION = "ANALYZE_IMAGE_CANCEL"
export const RESTORE_DEFAULT_DIGITIZATION = "RESTORE_DEFAULT_DIGITIZATION"
export const CALIBRATE_IMAGE_SIZE = "CALIBRATE_IMAGE_SIZE"
export const UPDATE_RULER_SIZE = "UPDATE_RULER_SIZE"
export const SAVE_DIGITIZATION = "SAVE_DIGITIZATION"
export const EDIT_DIGITIZATION = "EDIT_DIGITIZATION"
export const UPDATE_POINTS = "UPDATE_POINTS"
export const CROPING_MODE = "CROPING_MODE"
export const CROPING_DATA = "CROPING_DATA"

export const UPDATE_VIEW_SETTINGS = "UPDATE_VIEW_SETTINGS"
export const CHANGE_IMAGE_ATTRIBUTE = "CHANGE_IMAGE_ATTRIBUTE"

export const APPLAY_ANALYSIS = "APPLAY_ANALYSIS"
export const SELECT_ANALYSIS_LINE = "SELECT_ANALYSIS_LINE"

export const SET_ERROR = "SET_ERROR"
export const LOADING = "LOADING"
export const SET_TAB = "SET_TAB"

export const loadServiceAndLibraries = (serviceId, token) => async dispatch => {
    let response = await getService(token, serviceId)
    if (!response) {
        dispatch({ type: SET_ERROR, payload: "404" })
    }
    else {
        await response.files.map(async (file) => {
            if (file.name === "Lateral Cephalometry") {
                const meta = await getMeta(file.file)
                dispatch({
                    type: SET_LETRAL_CEPH_META,
                    payload: { width: meta.width, height: meta.height }
                })
                dispatch({ type: LATERAL_CEPH_IMAGE_UPLOADED })
                if(response.service_data && response.service_data.points && response.service_data.original_points) {
                    dispatch({
                        type: LOAD_DIGITIZATION_RESULT,
                        payload: {points: response.service_data.points, originalPoints: response.service_data.original_points}
                    })
                    let analysisList = store.getState().library.analysisList;
                    if(analysisList.length > 0) {
                        dispatch({
                            type: SAVE_DIGITIZATION,
                            payload: analysisList[0],
                        })
                    }
                }
            }
        })
        dispatch({
            type: LOAD_SERVICE,
            payload: { serviceData: response }
        })
    }
}

export const uploadImage = ({ uploadedImg, photo, token, serviceId }) => async dispatch => {
    let result = await clasifyLetralImage(uploadedImg.files[0])
    dispatch({ type: LATERAL_CEPH_IMAGE_CLASIFIED, payload: result })
    !photo.url
        ? await addServiceFile(serviceId, uploadedImg.files[0], photo.lable, token)
        : await updateServiceFile(serviceId, photo.id, uploadedImg.files[0], photo.lable, token)
    if (photo.lable === "Lateral Cephalometry") { 
        dispatch({ type: LATERAL_CEPH_IMAGE_UPLOADED })
        let response = await clasifyLetralImage(uploadedImg.files[0])
        console.log(response.result)
        dispatch({ type: LATERAL_CEPH_IMAGE_CLASIFIED, payload: response.result })
    }
    dispatch(loadServiceAndLibraries(serviceId, token))
}


export const analyzeImage = (serviceId, token) => async dispatch => {
    dispatch({ type: LOADING })
    const LateralCephalometry = store.getState().cephalometry.casePhotos.find(casePhoto => casePhoto.lable === "Lateral Cephalometry")
    try {
        let response = await analysisLetralImage(serviceId, LateralCephalometry.url, token)
        await updateService(token, serviceId, {
            original_points: response.response_data,
        })
        const formatedPoint = formatNodes(response.response_data)
        dispatch({ type: DIGITIZE_IMAGE, payload: {formatedPoint, originalPoints: response.response_data} })
    } catch (error) {
        dispatch({ type: SET_ERROR, payload: error.message })
    }
}

export const clearImage = () => ({
    type: CLEAR_DIGITIZATION,
    payload: {}
})

export const restoreDefaultDigitization = () => dispatch => {
    dispatch({
        type: RESTORE_DEFAULT_DIGITIZATION,
        payload: {}
    })
}

export const calibrateImageSize = () => ({
    type: CALIBRATE_IMAGE_SIZE
})

export const updateRulerSise = (rulerSize) => ({
    type: UPDATE_RULER_SIZE,
    payload: rulerSize
})

export const updatePoints = ({ name, x, y }) => ({
    type: UPDATE_POINTS,
    payload: { name, x, y }
})

export const saveDigitization = (analysis) => async dispatch => {
    dispatch({
        type: SAVE_DIGITIZATION,
        payload: analysis
    })
    let token = store.getState().user.token
    let serviceId = store.getState().cephalometry.serviceId

    let points = store.getState().cephalometry.points
    let originalPoints = store.getState().cephalometry.originalPoints

    await updateService(token, serviceId, {
        points: points,
        original_points: originalPoints
    })
}

export const editDigitization = () => ({
    type: EDIT_DIGITIZATION,
    payload: {}
})

export const cropingMode = (value) => ({ 
    type: CROPING_MODE, 
    payload: value 
})

export const updateCropingData = (data) => ({ 
    type: CROPING_DATA, 
    payload: data 
})




export const saveCropingResult = () => dispatch => {
    const cephalometry = store.getState().cephalometry
    const user = store.getState().user

    const casePhoto = cephalometry.casePhotos.find(casePhoto => casePhoto.lable === "Lateral Cephalometry")
    const crop = cephalometry.cropingData.crop
    const image = cephalometry.cropingData.image

    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';
    ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height,
    );
    const base64Image = canvas.toDataURL('image/jpeg');
    const file = dataURLtoFile(base64Image, "Lateral.png")
    dispatch(uploadImage({ uploadedImg: {files: [file]}, photo: casePhoto, serviceId: cephalometry.serviceId, token: user.token }))
    dispatch({type: CROPING_MODE, payload: false })
    dispatch({ type: CROPING_DATA, payload: {}})
}

export const updateViewSettings = (settings) => ({
    type: UPDATE_VIEW_SETTINGS,
    payload: settings
})

export const changeAttribute = ({ newValue, attribute }) => ({
    type: CHANGE_IMAGE_ATTRIBUTE,
    payload: { newValue, attribute }
})


export const applyAnalysis = (analysis) => ({
    type: APPLAY_ANALYSIS,
    payload: analysis
})

export const selectAnalysisLine = (data) => ({
    type: SELECT_ANALYSIS_LINE,
    payload: data
})

export const setTabState = (tab) => ({
    type: SET_TAB,
    payload: tab
})

