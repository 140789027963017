import { curve_control } from "../constant/constants"

export const convertToSlug = title => {
    return title.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '')
}

export const convertToTitle = slug => {
    return slug.toLowerCase().replace(/-/g, ' ')
}

export const formateNumber = number => {
    if(!number)
        return "00"
    if(number < 10)
        return "0" + number.toString()
    return number.toString()
}
export const getMeta = (url) => {
    return new Promise(async (resolve, reject) => {
    const img = new Image();
    img.src = url;
    img.onload = function() {
        resolve( {width: this.naturalWidth, height: this.naturalHeight});
    }
    })
}
export const convertBase64 = file => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader()
        fileReader.readAsDataURL(file)
        fileReader.onload = () => resolve(fileReader.result)
        fileReader.onerror = error => reject(error)
    })
}

export const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[arr.length - 1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
}


export const formatNodes = points => {
    let formatedPoints = []

    for (const [key, value] of Object.entries(points)) {
        formatedPoints = [...formatedPoints, {
            "name": key,
            "x": value[0],
            "y": value[1],
            "Ox": value[0],
            "Oy": value[1],
        }]
    }

    //! Add Lable & Type To Points ...
    let pointWithoutLable = ["R1", "R2", "ppOcP", "Dl6", "Ml6", "Mal6", "Du6", "Mu6", "Mau6", "Ap1u", "Ap1l", "Ls1u", "Li1l", "Is1l", "Is1u"]
    let pointUpperFace = ["CoTg", "Ls", "N’", "Gl’", "Stm-s", "Ns",]
    let pointLowerFace = ["Li", "Sm", "Gn’", "Me’", "Pog’", "Stm-i", "Sn"]

    formatedPoints.forEach((point, index) => {
        if (pointLowerFace.includes(point.name)) {
            formatedPoints[index] = { ...formatedPoints[index], "type": "lower-face", }
        }
        if (pointUpperFace.includes(point.name)) {
            formatedPoints[index] = { ...formatedPoints[index], "type": "upper-face", }
        }
        if (!pointWithoutLable.includes(point.name)) {
            formatedPoints[index] = { ...formatedPoints[index], "lable": true, }
        }
        if (point.name === "Mal6" || point.name === "Mau6" || point.name === "R1" || point.name === "R2") {
            formatedPoints[index] = { ...formatedPoints[index], "visible": false, }
        }
        else {
            formatedPoints[index] = { ...formatedPoints[index], "visible": true, }
        }
    })

    // ! Add Curve Points to Points List...
    curve_control.forEach((control) => {
        var src = formatedPoints.find((point) => point.name === control.src)
        var dis = formatedPoints.find((point) => point.name === control.dis)

        formatedPoints = [...formatedPoints, {
            name: control.name,
            type: control.type,
            x: ((dis.x - src.x) * control["x-parameter"]) + src.x,
            y: ((dis.y - src.y) * control["y-parameter"]) + src.y,
        }]
    })

    let orangePoints = ["ppCond", "T1", "Im", "T2", "Gn", "Pr"]
    formatedPoints.forEach((point, index) => {
        let pointColor
        if (point.type === "lower-face" || point.type === "upper-face") {
            pointColor = 'green'
        }
        else if (point.type === "control") {
            pointColor = "#9508f6"
        }
        else if (orangePoints.includes(point.name)) {
            pointColor = 'orange'
        }
        else {
            pointColor = "red"
        }
        formatedPoints[index] = {
            ...formatedPoints[index],
            "color": pointColor,
        }
    })
    return formatedPoints
}
